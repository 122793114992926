import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import { FilterScreenContext } from './utility/context/FilterScreenContext'
import Spinner from './@core/components/spinner/Fallback-spinner'
import './@core/components/ripple-button'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@styles/react/libs/toastify/toastify.scss'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import { Amplify } from 'aws-amplify'
import { testConfig, prodConfig } from './sp-aws-exports'

if (process.env.REACT_APP_BASEURL.indexOf('//api') > 0) Amplify.configure(prodConfig)
else Amplify.configure(testConfig)

import * as serviceWorker from './serviceWorker'

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false')

    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false')
      resolve(component)
    }).catch((error) => {
      if (!hasRefreshed) {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true')
        return window.location.reload()
      }
      reject(error)
    })
  })
}

const LazyApp = lazy(() => lazyRetry(() => import('./App')))

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
        <FilterScreenContext>
          <LazyApp />
          <ToastContainer newestOnTop />
        </FilterScreenContext>
      </ThemeContext>
    </Suspense>
  </Provider>, rootElement)
} else {
  ReactDOM.render(<Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
        <FilterScreenContext>
          <LazyApp />
          <ToastContainer newestOnTop />
        </FilterScreenContext>
      </ThemeContext>
    </Suspense>
  </Provider>, rootElement)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
serviceWorker.unregister()