import { useState, createContext } from 'react'

const FilterScreenStates = createContext()

const FilterScreenContext = ({ children }) => {

  const [results, setResults] = useState([])
  const [sport, setSport] = useState('')
  const [sportBadge, setSportBadge] = useState([])
  const [date, setDate] = useState(new Date())
  const [distance, setDistance] = useState(50000)

  return (<FilterScreenStates.Provider value={{ results, setResults, sport, setSport, sportBadge, setSportBadge, date, setDate, distance, setDistance }}>{children}</FilterScreenStates.Provider>)
}

export { FilterScreenStates, FilterScreenContext }