import logo from '@src/assets/images/logo/logo.png'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100 bg-dark d-flex justify-content-between align-items-center'>
      <img className='fallback-logo' src={logo} alt='Shareplay' />
    </div>
  )
}

export default SpinnerComponent
