// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: 'User Portal',
    appLogoImage: require('@src/assets/images/logo/logo.png').default,
    appLogoLgImage: require('@src/assets/images/logo/splogo-h.png').default,
    playOnImage: require('@src/assets/images/icons/play-on.svg').default,
    playOffImage: require('@src/assets/images/icons/play-off.svg').default,
    coachOnImage: require('@src/assets/images/icons/coach-on.svg').default,
    coachOffImage: require('@src/assets/images/icons/coach-off.svg').default,
    arrowPlayImage: require('@src/assets/images/icons/arrow-play.svg').default,
    arrowCoachImage: require('@src/assets/images/icons/arrow-coach.svg').default
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: false // Enable scroll to top button
  }
}

export default themeConfig
