export const prodConfig = {
  aws_project_region: 'ap-south-1',
  aws_cognito_region: 'ap-south-1',
  aws_user_pools_id: 'ap-south-1_aN8cSRoeR',
  aws_user_pools_web_client_id: 'qi5apcjkf3j0m39v7rckqhad5',
  oauth: {
    domain: 'shareplay.auth.ap-south-1.amazoncognito.com',
    scope: [
      'aws.cognito.signin.user.admin',
      'email',
      'openid',
      'phone',
      'profile'
    ],
    redirectSignIn: 'https://app.shareplay.in/home',
    redirectSignOut: 'https://app.shareplay.in/logout',
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
}

export const testConfig = {
  aws_project_region: 'ap-south-1',
  aws_cognito_region: 'ap-south-1',
  aws_user_pools_id: 'ap-south-1_bG1OfqQmt',
  aws_user_pools_web_client_id: 'v1ffo43r2n3igv3j3jglsrr2a',
  oauth: {
    domain: 'sply-test.auth.ap-south-1.amazoncognito.com',
    scope: [
      'aws.cognito.signin.user.admin',
      'email',
      'openid',
      'phone',
      'profile'
    ],
    redirectSignIn: 'https://test-ui.shareplay.in/home',
    redirectSignOut: 'https://test-ui.shareplay.in/logout',
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
}

